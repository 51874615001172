import React from 'react';
import logoWhite from '../../images/Logo_White.svg';

const MarketingFooter = () => (
    <footer className="w-full px-4 lg:px-0 mt-24 text-white bg-secondary-dark">
        <div className="container xl:px-40 mx-auto pt-8">
          <div className="border-b">
            <img src={logoWhite} alt="Kodama" />
            <p className="text-xs my-2">Copyright 2021</p>
          </div>
        </div>
        <div className="pb-8 container xl:px-40 mx-auto grid grid-cols-1 lg:grid-cols-4 gap-10">
          <div className="">
            <div className="mt-5">
                <div className="mb-6">
                  <p className="font-bold">About</p>
                </div>
                {/* <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">FAQ</a>
                </div> */}
                {/* <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Contact</a>
                </div> */}
                <div className="mb-3">
                  <a href="/terms" className="hover:underline text-sm">Terms of Service</a>
                </div>
                <div className="mb-3">
                  <a href="/privacy" className="hover:underline text-sm">Privacy Policy</a>
                </div>
                {/* <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Careers</a>
                </div> */}
            </div>
          </div>
          <div className="">
            <div className="mt-5">
                <div className="mb-6">
                  <p className="font-bold">API</p>
                </div>
                <div className="mb-3">
                  <a href="https://kodama.stoplight.io/" className="hover:underline text-sm">API Docs</a>
                </div>
                {/* <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Buying Offsets</a>
                </div>
                <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Trading Offsets</a>
                </div> */}
            </div>
          </div>
          <div className="">
            {/* <div className="mt-5">
                <div className="mb-6">
                  <p className="font-bold">Funding</p>
                </div>
                <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Fund Projects</a>
                </div>
                <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Kodama Carbon Fund</a>
                </div>
                <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Partnerships</a>
                </div>
            </div> */}
          </div>
          <div className="">
            {/* <div className="mt-5">
                <div className="mb-6">
                  <p className="font-bold">Solutions</p>
                </div>
                <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Business Offsets</a>
                </div>
                <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Personal Offsets</a>
                </div>
                <div className="mb-3">
                  <a href="#" className="hover:underline text-sm">Sustainability Projects</a>
                </div>
            </div> */}
          </div>
        </div>
      </footer>
);

export default MarketingFooter;
