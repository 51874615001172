import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import logoWhite from '../../images/Logo_White.svg';

const NavItems = [
    { Title: "Home", Url: "/" },
    { Title: "API Docs", Url: "https://kodama.stoplight.io/" },
    { Title: "Pricing", Url: "/pricing" },
    { Title: "Offsetting", Url: "/exchange" },
    { Title: "Tracking", Url: "/tracking" },
    { Title: "Solutions", Url: "#", Links: [
        { Title: "Emission Tracking", Url: "/tracking" },
        { Title: "Carbon Credit Brokerages", Url: "/brokerage-solutions" },
        { Title: "Logistics Solutions", Url: "/logistics-solutions" },
        // { Title: "eCommerce Solutions", Url: "/ecommerce-solutions", }
    ] },
];

const Navbar = () => {

    const [burgerOpen, setBurgerOpen] = useState(false);
    const toggle = () => {
        setBurgerOpen(!burgerOpen);
    }
    
    const menuContainerRef = useRef(null);

    useEffect(() => {
        if (burgerOpen) {
            menuContainerRef.current.focus();
        }
    });

    return (
        <nav className="h-16 pt-4 pb-2 flex flex-row flex-wrap items-center container xl:px-40 mx-auto"> 
            <div className="w-full flex-grow lg:flex-grow-0 lg:w-auto px-2 flex flex-row justify-between items-center mb-2 lg:mb-0  ">
                <div>
                    <img src={logoWhite} alt="Kodama" />
                </div>
                <div className={
                    (burgerOpen ? "bg-primary-dark " : "bg-none ") +
                    "text-white lg:hidden rounded-full p-1"
                    }
                    onClick={toggle}
                >
                    <svg className="w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#FFFFFF">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </div>
            </div>
                <div
                    id="menu-items-container"
                    key="what"
                    ref={menuContainerRef}
                    className="w-full z-10 lg:w-auto lg:flex-1"
                    onBlur={() => setBurgerOpen(false)}
                >
                    <div
                        className={
                            (burgerOpen ? "flex " : "hidden ") +
                            "lg:flex overflow-visible items-center flex-wrap flex-row text-primary-dark z-10 rounded-lg shadow-lg border border-gray-100 bg-white lg:mt-0 lg:ml-4 lg:bg-opacity-0 lg:shadow-none lg:rounded-none lg:text-white lg:border-none"}
                    >
                        <>
                            {NavItems.map(item => (
                                <div className="relative dropdown w-full lg:w-auto py-1 lg:py-0">
                                    <Link key={item.Title} to={item.Url} className="w-full cursor-pointer hover:underline hover:bg-gray-100 lg:hover:bg-transparent lg:w-auto lg:flex-grow-0 flex-grow px-3">{item.Title}</Link>
                                    {item.Links && (
                                        <div className="dropdown-menu block lg:hidden lg:absolute z-50 bg-white pl-8 lg:p-4 rounded text-primary-dark w-auto">
                                            {item.Links.map(link => (
                                                <div className="whitespace-nowrap py-1">
                                                    <Link className="cursor-pointer hover:underline" to={link.Url} key={link.Title}>{link.Title}</Link>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                            <div className="flex-grow" />
                            {/* <div className="px-4"> */}
                            <Link className="w-full cursor-pointer hover:underline hover:bg-gray-100 lg:hover:bg-transparent lg:w-auto lg:flex-grow-0 flex-grow px-2" to="/app/login">Login</Link>
                            {/* </div> */}
                            {/* <div className="p-2 px-4 rounded-md bg-primary text-white"> */}
                            <Link className="w-full border border-white rounded cursor-pointer hover:underline hover:bg-gray-100 lg:hover:bg-transparent lg:w-auto lg:flex-grow-0 flex-grow px-2 py-1" to="/app/register">Sign Up</Link>
                            {/* </div> */}
                        </>
                    </div>
            </div>
           
        </nav>
    );
}

export default Navbar;
