import React, { useEffect, useState } from 'react';
import { Button, Input } from '@chakra-ui/react'
import Navbar from './NavBar';
import { Link, navigate } from 'gatsby';
import nyc from '../images/nyc_daily.png';
import room from '../images/room_cans.png';
import MarketingFooter from './MarketingFooter';
import MarketingHead from './marketing-meta';


const StickerPage = ({stickerName, stickerImage}) => {

  const trackEmail = (e) => {
      window.analytics.identify({
        email: e.target.value
      })
  }
  
  return (
      <>
        <title>Kodama - {stickerName} 1 Ton Offset</title>
        <MarketingHead />
        <div className={'bg-hero-image bg-cover p-1 text-white'}>
          <Navbar /> 
          <div className="container xl:px-40 mt-10 lg:h-33vh mb-8 mx-auto grid grid-cols-1 lg:grid-cols-2 items-top">
              <div className="min-h-96 p-2 w-full">
                  <p className="text-4xl mb-8 font-bold">1 ton of CO2</p>
                  <p className="mt-2 text-2xl leading-relaxed">The sticker you just scaned offset 1 ton of CO2.
It’s about how much CO2 emitted from 100 gallons of gasoline, or driving nearly 2000 miles.</p>
              </div>
              <div className="p-2 flex items-center">
                <div className="lg:flex-grow"></div>
                <div className="w-full lg:width-auto flex justify-center">
                    <img src={stickerImage} className="w-96 shadow-lg" />
                </div>
              </div>
          </div> 
        </div>
        <div className="bg-gray-100 border-b border-gray-300 ">
            <div className="container mx-auto px-4 xl:px-40 p-2">
                <div className="rounded-md w-full lg:w-1/2 bg-white p-4 border border-gray-300 flex justify-between">
                    <div className="font-bold">Kodama {stickerName} 1 Ton Sticker</div>
                    <Link to="/stickers"><a className="text-primary underline">$16 | Buy Now</a></Link>
                </div>
            </div>
        </div>
        <div className="container xl:px-40 mt-8 mb-5 mx-auto px-4">
            <div className="w-full lg:w-1/2 mt-4 p-4 bg-white shadow-lg border border-gray-100 rounded-lg">
                <h2 className="text-2xl mb-3">Sign up for email updates, and we'll offset another 1kg of CO2</h2>
                <Input placeholder="Enter your email"></Input>
                <div className="mt-2">
                    <Button onClick={trackEmail} colorScheme="green">Submit</Button>
                </div>
            </div>
        </div>
        <div className="container xl:px-40 mb-5 mt-24 mx-auto px-4">
            <h2 className="text-5xl font-bold text-primary">Kodama helps repair our climate</h2>
            <p className="mt-4 text-3xl text-primary-dark leading-loose">We make it easy to meet your sustainability goals, from offsetting your personal footprint to managing sustainability goals for an entire company.</p>
            <p className="text-3xl text-primary underline cursor-pointer"><Link to="/exchange">Learn more about offsetting with Kodama</Link></p>
        </div>
        <div className="container xl:px-40 mt-24 mb-5 mx-auto px-4 grid items-center flex-col-reverse gap-8 grid-cols-1 lg:grid-cols-12">
            <div className="col-span-7">
                <img className="rounded-xl shadow-xl" src={nyc} />
            </div>
            <div className="col-span-5 order-first lg:order-last">
                <h2 className="text-primary font-bold text-5xl">Visualizing emissions</h2>
                <p className="leading-loose mt-4">Each ball in this image is 1 ton of CO2. The pile, taller than the Empire Sate Building, is 1 day’s worth of emissions from New York City.</p>
            </div>
        </div>
        <div className="container xl:px-40 mt-24 mb-5 mx-auto px-4 grid items-center gap-8 grid-cols-1 lg:grid-cols-12">
            <div className="col-span-5">
                <h2 className="text-primary font-bold text-5xl">The air you breathe</h2>
                <p className="leading-loose mt-4">In the room you’re in right now, there is enough carbon dioxide attributed to Chevron, ExxonMobil, and Saudi Aramco to fill three 12oz cans.</p>
            </div>
            <div className="col-span-7">
                <img className="rounded-xl shadow-xl" src={room} />
            </div>
        </div>
        <MarketingFooter />
      </>
  )
};

export default StickerPage;
