import React from 'react';

const MarketingHead = () => {
    
    return (
        <>
            <meta name="title" content="Kodama - Your digital sustainability consultant." />
            <meta name="description" content="Kodama makes it easy to achieve your sustainability goals. Easily track, offset and understand your carbon footprint."/>
            <meta name="keywords" content="carbon, co2, carbon credits, carbon offset, VCU, VCUs, kodama, digital sustainability, sustainability, sustainability consultant, carbon offset api, api, co2 api, emissions tracking, logistics emissions, freight offsets, airfare offsets"/>
            <meta name="robots" content="index, follow"/>
            <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta name="language" content="English"/>
        </>
    )
};

export default MarketingHead;
